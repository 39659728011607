.brandNameWrapper {
  margin-bottom: 50px;
}
.brandNameWrapper,
.navLinkWrapper {
  display: flex;
}
.logo {
  width: 52px;
  height: 38px;
}
.brandName {
  font-size: 30px;
  font-family: "Sniglet", cursive;
  color: #57428b;
}
.navLinkWrapper {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 7px;
  color: #c5c4c9;
  transition: 1s background-image ease-in-out, 0.2s color;
}
.navLinkIcon {
  font-size: 20px !important;
}
.navLink {
  padding-left: 15px;
  font-size: 16px;
  display: grid;
  place-items: center;
}
.navLinkWrapper:hover,
.activeNavLink {
  background-image: linear-gradient(
    90deg,
    #fb5303 0%,
    rgba(251, 83, 3, 0.73) 100%
  );
  color: white;
}
