.titleBarWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  overflow: hidden;
}
.title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 3px;
}
.inputWrapper {
  display: flex;
  flex-wrap: wrap;
}
.input {
  width: calc(50% - 20px);
  margin-right: 20px;
  margin-bottom: 30px;
}
.btnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.cancelBtn,
.saveBtn {
  border-radius: 5px;
  padding: 10px 30px;
  color: white;
  font-size: 20px;
  border: none;
}
.cancelBtn {
  background-color: #f8495c;
  margin-right: 20px;
}
.saveBtn {
  background-color: #3c9f71;
}
