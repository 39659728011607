.uploadWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 40px;
}

.uploadSection {
  display: flex;
  align-items: center;
}
.uploadIcon {
  font-size: 40px !important;
  color: #62abff;
  margin-right: 15px;
}
.productImage {
  width: 150px;
  height: 150px;
}
.message {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.uploadBtn {
  cursor: pointer;
  color: white;
  font-size: 15px;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  background-color: rgba(38, 153, 251, 0.9);
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  max-height:60px;
}
.uploadBtn:hover {
  background-color: rgba(38, 153, 251, 1);
}
