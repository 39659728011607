@import url('https://fonts.googleapis.com/css2?family=Ruda:wght@400;500;600;700;800;900&family=Sniglet&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
  font-family: "Ruda", sans-serif;
}
*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
	border-radius: 10px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
	background-color: rgba(0,0,0,.1);
}


.no-print {
	display: block;
}
.print {
	display: none;
}
.print-layout {
	width: 100%;
	background-color: white;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}

@media print
{
	.no-print
	{
		display: none !important;
	}
	.print
	{
		display: block !important;
	}
}