.title {
  font-size: 20px;
}
.uploadWrapper {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
}

.uploadSection {
  display: flex;
  align-items: center;
}
.uploadIcon {
  font-size: 40px !important;
  color: #62abff;
  margin-right: 15px;
}
.message {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.uploadBtn {
  cursor: pointer;
  color: white;
  font-size: 15px;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  background-color: rgba(38, 153, 251, 0.9);
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
}
.uploadBtn:hover {
  background-color: rgba(38, 153, 251, 1);
}
.btnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}
.submitBtn,
.closeBtn {
  cursor: pointer;
  color: white;
  font-size: 15px;
  border-radius: 5px;
  padding: 5px 10px;
  border: none;
  background-color: rgba(247, 61, 82, 0.9);
  transition: background-color 0.3s;
}
.submitBtn {
  background: rgba(60, 159, 113, 0.9);
  margin-left: 10px;
}
.submitBtn:hover {
  background: rgba(60, 159, 113, 1);
}
.closeBtn:hover {
  background-color: rgba(247, 61, 82, 1);
}
.ordertitle {
  font-size: 20px;
  margin-bottom: 15px;
}
.detaildiv {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.titlediv {
  width: 50%;
}
.contentdiv {
  width: 50%;
}
.orderdetail {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #fb5303;
}
