/* title bar  */
.titleBarWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  overflow: hidden;
}
.titleWrapper {
  display: flex;
  flex-direction: column;
}
.title {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 3px;
}
.link {
  color: rgba(1, 0, 5, 0.9) !important;
}
.activeLink {
  color: rgba(42, 39, 61, 0.62);
}
/* filter section */
.filterWrapper {
  height: 45px;
  display: flex;
}
.searchBarWrapper {
  min-width: 250px;
  min-height: 45px;
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding: 10px;
  border-radius: 7px;
  background-color: #eff5fa;
  color: #c7c9ca;
}
.searchIcon {
  font-size: 20px !important;
  margin-right: 5px;
}
.searchBar {
  flex: 1;
  background: transparent;
  border: none;
  font-size: 15px;
}
.searchBar:focus {
  outline: none;
}

.coloredBtn {
  cursor: pointer;
  color: white;
  font-size: 15px;
  padding: 15px 10px;
  border-radius: 9px;
  background: linear-gradient(90deg, #fb5303 0%, rgba(251, 83, 3, 0.73) 100%);
}

/* listing section */
.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
