.ContentsWrapper {
  height: 100vh;
  width: 100vw;
  padding: 15px;
  background: #e5e5e5;
  display: flex;
  overflow: auto;
}

.NavbarWrapper {
  height: 100%;
  width: 17%;
  min-width: 220px;
  border-radius: 10px;
  padding: 20px 15px;
  background: white;
  margin-right: 15px;
  overflow-x: hidden;
}

.ContentsArea {
  position: relative;
  background-color: white;
  border-radius: 10px;
  height: 100%;
  flex: 1;
  min-width: 700px;
  padding: 20px 15px;
  overflow-x: auto;
  overflow-y: auto;
}

/* pop up container */
.container {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
}
.wrapper {
  min-width: 430px;
  padding: 30px 35px;
  border-radius: 8px;
  background: white;
}

/* user layout */
.userWrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
}
.logoWrapper {
  width: 35%;
  height: 100%;
  display: flex;
  padding: 50px 120px;
  padding-right: 0px;
  background-image: linear-gradient(90deg, #fb5303 0%, #fc8046 100%);
}
.logoContainer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(92.99deg, #fb5303 2.24%, #fc8046 97.34%);
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}
.logoImage {
  width: 180px;
  height: 130px;
}
.contentWrapper {
  flex: 1;
  height: 100%;
  display: flex;
  padding: 50px 120px;
  padding-left: 0px;
  background: white;
}
.contentContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 25px;
  background: white;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
}
.brandLogo {
  align-self: flex-end;
  width: 210px;
  height: 45px;
}
.outletWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 75%;
  justify-content: center;
}
