.title {
  font-size: 45px;
  font-weight: 300;
  text-align: center;
  margin-bottom:30px;
}
.link {
  align-self: flex-end;
  margin-top: 20px;
  font-size: 20px;
  color: rgba(67, 74, 83, 1);
}
.link:hover {
  text-decoration: underline;
}

.btn {
  font-size: 30px;
  color: white;
  background-image: linear-gradient(90deg, #fb5303 0%, #fc8046 100%);
  border-radius: 10px;
  border: none;
  padding: 8px 0;
  margin-top:40px;
}
