.cardWrapper {
  position: relative;
  width: 167px;
  height: 250px;
  padding: 10px;
  margin: 0 10px 30px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  color: black;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.13) 0%,
    rgba(218, 218, 218, 0.13) 100%
  );
  transition: background 0.5s;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
  border-radius: 16px;
}
.cardWrapper:hover {
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.3) 0%,
    rgba(218, 218, 218, 0.3) 100%
  );
}
.actionWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 3;
}
.image {
  z-index: 1;
  width: 120px;
  height: 120px;
  border-radius: 50%;
}
.title {
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
}
.disc {
  text-align: center;
  font-size: 12px;
  font-weight: 300;
}
.price {
  margin-top: 5px;
  font-size: 15px;
  font-weight: 700;
  align-self: flex-end;
}
